import { useState } from 'react';

import api from 'api';
import { getAudioDuration } from './audio-duration';

export default function useFileInput(args: {
  name: string;
  setValue: (name: string, value: string) => void;
  setError: (name: string, type: string, value: string) => void;
  clearError: (name: string) => void;
  image?: boolean;
  type?: 'public' | 'private';
  dir?: string;
  defaultUrl?: string;
}) {
  const {
    image = false,
    type = 'public',
    dir = 'others',
    defaultUrl,
    name,
    setValue,
    setError,
    clearError,
  } = args;
  const [file, setFile] = useState(defaultUrl || '');
  const [url, setUrl] = useState(defaultUrl || '');
  const [duration, setDuration] = useState(-1);
  const [size, setSize] = useState(0);
  const [status, setStatus] = useState({
    progress: 0,
    indefinite: false,
    inProgress: false,
  });
  function handleSelect(e: React.ChangeEvent<HTMLInputElement>) {
    if (!(e.target.files && e.target.files.length)) {
      setFile('');
      return;
    }
    const file = e.target.files[0];
    setFile(image ? URL.createObjectURL(file) : file.name);
    setSize(file.size);

    const formData = new FormData();
    formData.append('file', file);

    getAudioDuration(file).then(res => {
      setDuration(res);
    });

    api
      .post(`/files/${type}/${dir}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: progressEvent => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') ||
              progressEvent.target.getResponseHeader(
                'x-decompressed-content-length'
              );
          if (totalLength) {
            setStatus({
              progress: progressEvent.loaded / totalLength,
              inProgress: true,
              indefinite: false,
            });
          } else {
            setStatus({ progress: 0, inProgress: true, indefinite: false });
          }
        },
      })
      .then(res => {
        if (res.status === 201) {
          setUrl(type === 'private' ? res.data.key : res.data.url);
          setValue(name, type === 'private' ? res.data.key : res.data.url);
          setStatus({ ...status, inProgress: false });
          clearError(name);
        } else {
          throw Error();
        }
      })
      .catch(_e => {
        setError(name, 'submission', "Couldn't upload file");
      });
  }

  function reset() {
    setFile('');
    setUrl('');
    setDuration(-1);
    setStatus({
      progress: 0,
      indefinite: false,
      inProgress: false,
    });
  }
  return { file, status, url, duration, size, handleSelect, reset };
}
