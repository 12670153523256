import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from './store';
import api from 'api';
import { LoginData } from 'screens/Login';

interface NotificationData {
  title: string;
  body: string;
  bookId?: string;
}

interface AuthState {
  token: string | null;
  user?: {
    id: string;
    name: string;
    username: string;
    role: 'ADMIN' | 'EDITOR' | 'USER';
  };

  notificationStatus?: 'SUCCESS' | 'FAILED';

  loading?: boolean;
  message?: string;
}

const authData = JSON.parse(window.localStorage.getItem('auth') as string);
authData && (api.defaults.headers.Authorization = `Bearer ${authData.token}`);

const initialState: AuthState = { token: null, loading: false, ...authData };

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading(state, { payload }: PayloadAction<boolean>) {
      state.loading = payload;
    },
    notificationSuccess(state) {
      state.loading = false;
      state.notificationStatus = 'SUCCESS';
    },
    notificationFailed(state) {
      state.loading = false;
      state.notificationStatus = 'FAILED';
    },
    loginSuccess(state, { payload }: PayloadAction<AuthState>) {
      state.loading = false;
      state.message = '';
      state.user = payload.user;
      state.token = payload.token;
    },
    loginFailed(state, { payload }: PayloadAction<string>) {
      state.loading = false;
      state.message = payload;
    },
    logout(state) {
      state.token = null;
      state.user = undefined;
      window.localStorage.removeItem('auth');
    },
  },
});

export const login = (loginData: LoginData): AppThunk => async dispatch => {
  dispatch(setLoading(true));

  try {
    const res = await api.post('/users/password-login', loginData);

    const {
      token,
      user: { id, username = '', name = '', role = '' },
    } = res.data;

    // const { token } = res.data.token;

    dispatch(loginSuccess({ user: { id, username, name, role }, token }));

    window.localStorage.setItem(
      'auth',
      JSON.stringify({ user: { id, username, name, role }, token })
    );
    api.defaults.headers.Authorization = `Bearer ${token}`;
  } catch (e) {
    dispatch(loginFailed('Login failed'));
    throw e;
  }
};

export const sendNotification = (
  loginData: NotificationData
): AppThunk => async dispatch => {
  dispatch(setLoading(true));

  try {
    await api.post('/users/send-notification', loginData);

    dispatch(notificationSuccess());
  } catch (e) {
    dispatch(notificationFailed());
    throw e;
  }
};

export const {
  setLoading,
  loginSuccess,
  loginFailed,
  logout,
  notificationSuccess,
  notificationFailed,
} = authSlice.actions;

export default authSlice.reducer;
