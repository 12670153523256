import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'modules/store';
import {
  loadBook,
  openModal,
  editChapter,
  promptChapterDelete,
  cancelChapterDelete,
  deleteChapter,
} from 'modules/books';
import ChapterForm from 'components/ChapterForm';
import RouterLink from 'components/RouterLink';

export default () => {
  const { id } = useParams();
  const book = useSelector((state: RootState) =>
    state.books.books.find(x => x.id === id)
  );
  const { modalOpen, toDelete } = useSelector(
    (state: RootState) => state.books.chapters
  );
  const dispatch = useDispatch();

  useEffect(() => {
    id && dispatch(loadBook(id));
  }, [id, dispatch]);

  const cls = useStyles();

  if (!book) return null;

  return (
    <Container maxWidth="lg">
      <Modal open={modalOpen} className={cls.modal}>
        <Paper className={cls.formPaper}>
          <ChapterForm />
        </Paper>
      </Modal>
      {toDelete && (
        <Modal
          open={Boolean(toDelete)}
          className={cls.modal}
          keepMounted={false}
        >
          <Paper className={cls.formPaper}>
            <Typography variant="h5">
              Sure to delete {toDelete!.name}?
            </Typography>
            <Button
              onClick={() => dispatch(deleteChapter(book.id, toDelete!))}
              variant="contained"
              color="secondary"
              className={cls.promptButton}
            >
              Delete
            </Button>
            <Button
              onClick={() => dispatch(cancelChapterDelete())}
              variant="contained"
              color="primary"
              className={cls.promptButton}
            >
              Cancel
            </Button>
          </Paper>
        </Modal>
      )}
      <Paper className={cls.paper}>
        <Typography variant="h4">{book.name}</Typography>
        <Grid container spacing={3} className={cls.info}>
          <Grid item>
            <img alt="book cover" className={cls.cover} src={book.coverImage} />
          </Grid>
          <Grid item>
            <Typography variant="body1" paragraph>
              Authors: {book.authors?.map(x => x.name).join(', ')}
            </Typography>
            <Typography variant="body1" paragraph>
              Narrators: {book.narrators?.map(x => x.name).join(', ')}
            </Typography>
            <Typography variant="body1" paragraph>
              Categories: {book.categories?.map(x => x.name).join(', ')}
            </Typography>
            <Typography variant="body1" paragraph>
              Price: {book.price}
            </Typography>
            <audio controls>
              <source src={book.previewAudio} />
            </audio>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to={`/books/${book.id}/edit`}
          className={cls.button}
          startIcon={<EditIcon />}
        >
          Edit Book
        </Button>
        <Button
          onClick={() => dispatch(openModal())}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
        >
          Add Chapter
        </Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Chapter No</TableCell>
              <TableCell>Chapter Order</TableCell>
              <TableCell>Audio</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {book.chapters?.map(chapter => (
              <TableRow key={chapter.id}>
                <TableCell>{chapter.name}</TableCell>
                <TableCell>{chapter.no}</TableCell>
                <TableCell>{chapter.order}</TableCell>
                <TableCell>
                  <audio controls>
                    <source src={chapter.audio} />
                  </audio>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => dispatch(editChapter(chapter))}
                    size="small"
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => dispatch(promptChapterDelete(chapter))}
                    size="small"
                  >
                    <DeleteIcon color="error" fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};

const useStyles = makeStyles({
  formPaper: {
    padding: '2rem',
    marginBottom: '2rem',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: '2rem',
    marginBottom: '2rem',
  },
  button: {
    marginRight: '1rem',
  },
  cover: {
    maxWidth: '20rem',
    float: 'left',
  },
  info: {
    marginTop: '.5rem',
    marginBottom: '1rem',
  },
  promptButton: {
    margin: '1rem 1rem 0 0',
  },
});
