import { SliceCaseReducers } from '@reduxjs/toolkit';

import { GenericObject, createGenericModule, GenericState } from './common';
import { RootState } from './store';

export interface Author extends GenericObject {
  name: string;
  email: string;
  username: string;
  password: string;
}

interface AuthorsState extends GenericState<Author> {}

const { slice: authorSlice, thunks, adapter } = createGenericModule<
  Author,
  SliceCaseReducers<AuthorsState>
>({
  name: 'authors',
  initialState: {},
  reducers: {},
});

export const {
  openModal,
  closeModal,
  editObject,
  promptDelete,
  cancelDelete,
} = authorSlice.actions;

export const { fetchAll, create, update, del: deleteAuthor } = thunks;
export const authorSelectors = adapter.getSelectors(
  (state: RootState) => state.authors
);

export default authorSlice.reducer;
