export async function getAudioDuration(file: File): Promise<number> {
  const audioEl = document.createElement('audio');

  const promise = new Promise<number>(resolve => {
    audioEl.onloadedmetadata = _e => {
      resolve(audioEl.duration);
    };
  });

  audioEl.src = window.URL.createObjectURL(file);

  return promise;
}
