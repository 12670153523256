import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import CameraIcon from '@material-ui/icons/CameraAlt';
import AudioIcon from '@material-ui/icons/Audiotrack';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useForm from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import debounce from 'awesome-debounce-promise';

import { Book, createBook, updateBook, resetSubmission } from 'modules/books';
import { Author } from 'modules/authors';
import { Narrator } from 'modules/narrators';
import { RootState } from 'modules/store';
import api from 'api';
import useFileInput from 'lib/fileInput';
import { Publisher } from 'modules/publishers';

const searchEntity = debounce(async function (keyword: string, entity: string) {
  keyword = keyword.toLocaleLowerCase();
  const res = await api.get(`/${entity}`, { params: { limit: 500 } });
  if (res.status === 200) {
    const data = res.data.data;
    return keyword
      ? data.filter((x: any) => x.name.toLocaleLowerCase().includes(keyword))
      : data;
  }
  return [];
}, 500);

export function useEntity<T>(entity: string) {
  const [keyword, setKeyword] = useState('');
  const [options, setOptions] = useState<T[]>([]);
  useEffect(() => {
    if (!keyword) return;
    (async function () {
      setOptions(await searchEntity(keyword, entity));
    })();
  }, [keyword, entity]);

  return { keyword, setKeyword, options };
}

export default () => {
  const { id } = useParams<{ id: string }>();
  const currentlyEditing = useSelector((state: RootState) =>
    state.books.books.find(x => x.id === id)
  );
  const { saveFailed, message } = useSelector(
    (state: RootState) => state.books
  );
  const {
    register,
    errors,
    handleSubmit,
    reset,
    setValue,
    setError,
    clearError,
  } = useForm<Book>({
    defaultValues: currentlyEditing,
  });

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    dispatch(resetSubmission());
    if (id && !currentlyEditing) history.push('/books');
  }, [id, currentlyEditing, history, dispatch]);

  async function onSubmit(data: Book) {
    if (currentlyEditing) {
      await dispatch(updateBook({ ...currentlyEditing, ...data }));
    } else {
      await dispatch(createBook(data));
    }
    reset();
    history.goBack();
  }

  const [authorKeyword, setAuthorKeyword] = useState('');
  const [authorOptions, setAuthorOptions] = useState<Author[]>([]);
  useEffect(() => {
    if (!authorKeyword) return;
    (async function () {
      setAuthorOptions(await searchEntity(authorKeyword, 'authors'));
    })();
  }, [authorKeyword]);

  const [narratorKeyword, setNarratorKeyword] = useState('');
  const [narratorOptions, setNarratorOptions] = useState<Narrator[]>([]);
  useEffect(() => {
    if (!narratorKeyword) return;
    (async function () {
      setNarratorOptions(await searchEntity(narratorKeyword, 'narrators'));
    })();
  }, [narratorKeyword]);

  const [publisherKeyword, setPublisherKeyword] = useState('');
  const [publisherOptions, setPublisherOptions] = useState<Publisher[]>([]);
  useEffect(() => {
    if (!publisherKeyword) return;
    (async function () {
      setPublisherOptions(await searchEntity(publisherKeyword, 'publishers'));
    })();
  }, [publisherKeyword]);

  const { setKeyword: setCatKeyword, options: catOptions } = useEntity(
    'categories'
  );

  const {
    file: coverImage,
    status: coverStatus,
    handleSelect: handleImageSelect,
  } = useFileInput({
    name: 'coverImage',
    setValue,
    setError,
    clearError,
    dir: 'book-covers',
    image: true,
    defaultUrl: currentlyEditing && currentlyEditing.coverImage,
  });

  const {
    file: previewAudio,
    status: previewStatus,
    handleSelect: handlePreviewSelect,
  } = useFileInput({
    name: 'previewAudio',
    setValue,
    setError,
    clearError,
    dir: 'book-previews',
    defaultUrl: currentlyEditing && currentlyEditing.previewAudio,
  });

  useEffect(() => {
    register({ name: 'authors' });
    register({ name: 'narrators' });
    register({ name: 'publishers' });
    register({ name: 'categories' });
    register(
      { name: 'coverImage' },
      {
        validate: () => {
          return !coverStatus.inProgress || 'Wait for the upload to complete';
        },
      }
    );
    register(
      { name: 'previewAudio' },
      {
        validate: value => {
          if (!value && !previewStatus.inProgress)
            return 'Preview Audio is required';
          return !previewStatus.inProgress || 'Wait for the upload to complete';
        },
      }
    );
  }, [register, coverStatus.inProgress, previewStatus.inProgress]);

  const cls = useStyles();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        name="name"
        inputRef={register({ required: 'Name is required' })}
        error={Boolean(errors.name)}
        helperText={errors.name && errors.name.message}
        label="Name"
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <FormControl margin="normal" error={Boolean(errors.coverImage)}>
        <FormLabel>Cover Image</FormLabel>
        <input
          name="coverImage"
          id="coverImage"
          onChange={handleImageSelect}
          className={cls.fileInput}
          type="file"
          accept="image/*"
        />
        <label htmlFor="coverImage">
          <div className={cls.imageContainer}>
            {coverImage ? (
              <img alt="book cover" className={cls.image} src={coverImage} />
            ) : (
              <CameraIcon />
            )}
            {coverStatus.inProgress && (
              <div className={cls.progressContainer}>
                <CircularProgress
                  variant={coverStatus.indefinite ? 'indeterminate' : 'static'}
                  value={coverStatus.progress * 100}
                />
              </div>
            )}
          </div>
        </label>
        {errors.coverImage && (
          <FormHelperText>{errors.coverImage.message}</FormHelperText>
        )}
      </FormControl>
      <Autocomplete
        multiple
        defaultValue={currentlyEditing && currentlyEditing.authors}
        options={authorOptions}
        getOptionLabel={option => option.name}
        onChange={(_e, v) => setValue('authors', v)}
        renderInput={params => (
          <TextField
            {...params}
            onChange={e => setAuthorKeyword(e.target.value)}
            label="Authors"
            variant="outlined"
            fullWidth
            margin="normal"
          />
        )}
      />
      <Autocomplete
        multiple
        defaultValue={currentlyEditing && currentlyEditing.publishers}
        options={publisherOptions}
        getOptionLabel={option => option.name}
        onChange={(_e, v) => setValue('publishers', v)}
        renderInput={params => (
          <TextField
            {...params}
            onChange={e => setPublisherKeyword(e.target.value)}
            label="Publishers"
            variant="outlined"
            fullWidth
            margin="normal"
          />
        )}
      />
      <Autocomplete
        multiple
        defaultValue={currentlyEditing && currentlyEditing.narrators}
        options={narratorOptions}
        getOptionLabel={option => option.name}
        onChange={(_e, v) => setValue('narrators', v)}
        renderInput={params => (
          <TextField
            {...params}
            onChange={e => setNarratorKeyword(e.target.value)}
            label="Narrators"
            variant="outlined"
            fullWidth
            margin="normal"
          />
        )}
      />
      <Autocomplete
        multiple
        defaultValue={currentlyEditing && currentlyEditing.categories}
        options={catOptions}
        getOptionLabel={option => option.name}
        onChange={(_e, v) => setValue('categories', v)}
        renderInput={params => (
          <TextField
            {...params}
            onChange={e => setCatKeyword(e.target.value)}
            label="Categories"
            variant="outlined"
            fullWidth
            margin="normal"
          />
        )}
      />
      <TextField
        name="price"
        type="number"
        inputRef={register({ required: 'Price is required' })}
        error={Boolean(errors.price)}
        helperText={errors.price && errors.price.message}
        label="Price"
        variant="outlined"
        margin="normal"
      />
      <br />
      <TextField
        name="authorCut"
        type="number"
        inputRef={register({ required: 'Author share is required' })}
        error={Boolean(errors.authorCut)}
        helperText={errors.authorCut && errors.authorCut.message}
        label="Author share %"
        variant="outlined"
        margin="normal"
      />
      <TextField
        name="narratorCut"
        type="number"
        inputRef={register({ required: 'Narrator share is required' })}
        error={Boolean(errors.narratorCut)}
        helperText={errors.narratorCut && errors.narratorCut.message}
        label="Narrator share %"
        variant="outlined"
        margin="normal"
      />
      <TextField
        name="publisherCut"
        type="number"
        inputRef={register({ required: 'Publisher share is required' })}
        error={Boolean(errors.publisherCut)}
        helperText={errors.publisherCut && errors.publisherCut.message}
        label="Publisher share %"
        variant="outlined"
        margin="normal"
      />
      <br />
      <TextField
        name="originalPrice"
        type="number"
        inputRef={register({ required: 'Original price is required' })}
        error={Boolean(errors.originalPrice)}
        helperText={errors.originalPrice && errors.originalPrice.message}
        label="Original price"
        variant="outlined"
        margin="normal"
      />
      <FormControl
        fullWidth
        margin="normal"
        error={Boolean(errors.previewAudio)}
      >
        <FormLabel>Preview Audio</FormLabel>
        <input
          name="previewAudio"
          id="previewAudio"
          onChange={handlePreviewSelect}
          className={cls.fileInput}
          type="file"
          accept="audio/*"
        />
        <label htmlFor="previewAudio">
          <div className={cls.fileContainer}>
            {previewAudio ? (
              <span className={cls.file}>{previewAudio}</span>
            ) : (
              <AudioIcon />
            )}
            {previewStatus.inProgress && (
              <div className={cls.progressContainer}>
                <CircularProgress
                  variant={
                    previewStatus.indefinite ? 'indeterminate' : 'static'
                  }
                  value={previewStatus.progress * 100}
                />
              </div>
            )}
          </div>
        </label>
        {errors.previewAudio && (
          <FormHelperText>{errors.previewAudio.message}</FormHelperText>
        )}
      </FormControl>
      <TextField
        name="description"
        inputRef={register({ required: 'Description is required' })}
        error={Boolean(errors.description)}
        helperText={errors.description && errors.description.message}
        label="Description"
        variant="outlined"
        fullWidth
        multiline
        rows={8}
        margin="normal"
      />
      {saveFailed && (
        <FormControl error>
          <FormHelperText>{message}</FormHelperText>
        </FormControl>
      )}
      <div className={cls.buttonRow}>
        <Button type="submit" color="primary" variant="contained">
          Save
        </Button>
      </div>
    </form>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonRow: {
    display: 'flex',
    marginTop: '1rem',
  },
  gap: {
    flexGrow: 1,
  },
  saveAndNew: {
    marginLeft: '1rem',
  },
  fileInput: {
    display: 'none',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: '200px',
    minWidth: '100px',
    maxWidth: '300px',
    border: `1px solid ${theme.palette.text.primary}`,
    marginTop: '1rem',
    borderRadius: '4px',
    position: 'relative',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  fileContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: '100px',
    minWidth: '100px',
    border: `1px solid ${theme.palette.text.primary}`,
    marginTop: '1rem',
    borderRadius: '4px',
    position: 'relative',
    padding: '2rem',
  },
  file: {
    textAlign: 'center',
  },
  progressContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, .5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
