import React, { useEffect } from 'react';
import { RootState } from 'modules/store';
import { fetchBalance } from 'modules/sales';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Paper } from '@material-ui/core';

export default function Balance() {
  const balance = useSelector((state: RootState) => state.sales.balance);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBalance());
  }, [dispatch]);

  const cls = useStyles();

  return (
    <Paper className={cls.root}>
      <span className={cls.text}>Available Balance</span> <br />
      <span className={cls.number}>৳{balance || '-'}</span>
    </Paper>
  );
}

const useStyles = makeStyles({
  text: {
    fontSize: '2rem',
  },
  number: {
    fontSize: '3rem',
  },
  root: {
    margin: 'auto',
    maxWidth: '40rem',
    padding: '2rem',
    textAlign: 'center',
  },
});
