import { SliceCaseReducers } from '@reduxjs/toolkit';

import { GenericObject, createGenericModule, GenericState } from './common';
import { RootState } from './store';

export interface Narrator extends GenericObject {
  name: string;
  email: string;
  username: string;
  password: string;
}

interface NarratorsState extends GenericState<Narrator> {}

const { slice: narratorSlice, thunks, adapter } = createGenericModule<
  Narrator,
  SliceCaseReducers<NarratorsState>
>({
  name: 'narrators',
  initialState: {},
  reducers: {},
});

export const {
  openModal,
  closeModal,
  editObject,
  promptDelete,
  cancelDelete,
} = narratorSlice.actions;

export const { fetchAll, create, update, del: deleteNarrator } = thunks;
export const narratorSelectors = adapter.getSelectors(
  (state: RootState) => state.narrators
);

export default narratorSlice.reducer;
