import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import api from 'api';
import { ResponseStatus } from 'lib/response-status';
import { handleError } from './common';

interface SalesRecord {
  id: string;
  price: number;
  authorShare: number;
  narratorShare: number;
  publisherShare: number;
  createdAt: string;
}

const adapter = createEntityAdapter<SalesRecord>({
  selectId: record => record.id,
});

export const fetch = createAsyncThunk<
  SalesRecord[],
  {
    from: Date;
    to: Date;
    author?: string;
    narrator?: string;
    publisher?: string;
    book?: string;
  },
  { rejectValue: string }
>(
  'sales/fetch',
  async (
    { from, to, author, narrator, publisher, book },
    { rejectWithValue }
  ) => {
    try {
      const res = await api.get('/sales', {
        params: {
          fromDate: from.toString(),
          toDate: to.toString(),
          ...(book && { book }),
          ...(author && { author }),
          ...(narrator && { narrator }),
          ...(publisher && { publisher }),
        },
      });

      return res.data;
    } catch (e) {
      return handleError(e, rejectWithValue);
    }
  }
);

export const fetchBalance = createAsyncThunk<
  { balance: number },
  void,
  { rejectValue: string }
>('sales/fetchBalance', async (_, { rejectWithValue }) => {
  try {
    const res = await api.get('/sales/balance');

    return res.data;
  } catch (e) {
    return handleError(e, rejectWithValue);
  }
});

const salesSlice = createSlice({
  name: 'sales',
  initialState: {
    ...adapter.getInitialState(),
    ids: [] as string[],
    status: ResponseStatus.INACTIVE,
    balance: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetch.pending, state => {
      state.status = ResponseStatus.PENDING;
    });
    builder.addCase(fetch.fulfilled, (state, action) => {
      state.status = ResponseStatus.FULFILLED;
      adapter.setAll(state, action.payload);
    });
    builder.addCase(fetch.rejected, state => {
      state.status = ResponseStatus.REJECTED;
    });
    builder.addCase(fetchBalance.fulfilled, (state, action) => {
      state.status = ResponseStatus.FULFILLED;
      state.balance = action.payload.balance;
    });
  },
});

export default salesSlice.reducer;
