import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from './store';
import { Author } from './authors';
import { Narrator } from './narrators';
import { Category } from './categories';
import api from 'api';
import { Publisher } from './publishers';

export interface Paginated<T> {
  _link: {
    self: string;
    next: string;
  };
  resourceCount: number;
  numberOfPages: number;
  data: T[];
}

export interface Chapter {
  id: string;
  name: string;
  order: number;
  no?: string;
  audio: string;
  duration: number;
  fileSize: number;
}

export interface Book {
  id: string;
  name: string;
  authors?: Author[];
  narrators?: Narrator[];
  publishers?: Publisher[];
  chapters?: Chapter[];
  categories?: Category[];
  price: number;
  originalPrice: number;
  previewAudio: string;
  coverImage: string;
}

interface BooksState {
  books: Book[];
  pagination: {
    next: string;
    numberOfPages: number;
    resourceCount: number;
    pagesLoaded: number;
    currentPage: number;
  };
  loading?: boolean;
  message?: string;
  saveFailed: boolean;
  toDelete?: Book;

  chapters: {
    modalOpen: boolean;
    currentlyEditing?: Chapter;
    toDelete?: Chapter;
  };
}

const initialState: BooksState = {
  books: [],
  pagination: {
    next: '',
    numberOfPages: 0,
    pagesLoaded: 0,
    resourceCount: 0,
    currentPage: 0,
  },
  saveFailed: false,
  chapters: { modalOpen: false },
};

const authSlice = createSlice({
  name: 'books',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    loadSuccess(state, { payload }: PayloadAction<Paginated<Book>>) {
      state.loading = false;
      state.message = '';

      state.pagination.next = payload._link.next;
      state.pagination.numberOfPages = payload.numberOfPages;
      state.pagination.resourceCount = payload.resourceCount;
      state.pagination.pagesLoaded++;

      state.books = [...state.books, ...payload.data];
    },
    gotoPage(state, { payload }: PayloadAction<number>) {
      state.pagination.currentPage = payload;
    },
    loadFailed(state, { payload }: PayloadAction<string>) {
      state.loading = false;
      state.message = payload;
    },
    bookCreated(state, { payload }: PayloadAction<Book>) {
      state.books.push(payload);
      state.saveFailed = false;
      state.message = '';
    },
    bookUpdated(state, { payload }: PayloadAction<Book>) {
      const idx = state.books.findIndex(x => x.id === payload.id);
      if (idx !== -1) state.books[idx] = { ...state.books[idx], ...payload };
      state.saveFailed = false;
      state.message = '';
    },
    saveFailed(state, { payload }: PayloadAction<string>) {
      state.saveFailed = true;
      state.message = payload;
    },
    resetSubmission(state) {
      state.message = '';
      state.saveFailed = false;
      state.loading = false;
    },
    promptDelete(state, { payload }: PayloadAction<Book>) {
      state.toDelete = payload;
    },
    cancelDelete(state) {
      state.toDelete = undefined;
    },
    bookDeleted(state, { payload }: PayloadAction<Book>) {
      state.books = state.books.filter(x => x.id !== payload.id);
      state.toDelete = undefined;
    },
    bookLoaded(state, { payload }: PayloadAction<Book>) {
      const idx = state.books.findIndex(x => x.id === payload.id);
      if (idx !== -1) {
        state.books[idx] = { ...state.books[idx], ...payload };
      } else {
        state.books.push(payload);
      }
      state.loading = false;
      state.message = '';
    },
    openModal(state) {
      state.chapters.modalOpen = true;
      state.saveFailed = false;
      state.message = '';
    },
    closeModal(state) {
      state.chapters.modalOpen = false;
      state.chapters.currentlyEditing = undefined;
    },
    chapterAdded(
      state,
      { payload }: PayloadAction<{ bookId: string; chapter: Chapter }>
    ) {
      const book = state.books.find(x => x.id === payload.bookId);
      if (book && book.chapters) book.chapters.push(payload.chapter);
      state.saveFailed = false;
      state.message = '';
    },
    chapterUpdated(
      state,
      { payload }: PayloadAction<{ bookId: string; chapter: Chapter }>
    ) {
      const book = state.books.find(x => x.id === payload.bookId);
      if (!book || !book.chapters) return;

      const chapterIdx = book.chapters.findIndex(
        x => x.id === payload.chapter.id
      );
      if (chapterIdx !== -1) book.chapters[chapterIdx] = payload.chapter;

      state.chapters.currentlyEditing = undefined;
      state.saveFailed = false;
      state.message = '';
    },
    editChapter(state, { payload }: PayloadAction<Chapter>) {
      state.chapters.currentlyEditing = payload;
      state.chapters.modalOpen = true;
    },
    promptChapterDelete(state, { payload }: PayloadAction<Chapter>) {
      state.chapters.toDelete = payload;
    },
    cancelChapterDelete(state) {
      state.chapters.toDelete = undefined;
    },
    chapterDeleted(
      state,
      { payload }: PayloadAction<{ bookId: string; chapter: Chapter }>
    ) {
      const book = state.books.find(x => x.id === payload.bookId);
      if (!book || !book.chapters) return;

      book.chapters = book.chapters.filter(x => x.id !== payload.chapter.id);
      state.chapters.toDelete = undefined;
    },
  },
});

export const loadBooks = (): AppThunk => async (dispatch, getState) => {
  const pagesLoaded = getState().books.pagination.pagesLoaded;
  if (pagesLoaded > 0) return;
  dispatch(startLoading());

  try {
    const res = await api.get('/books');

    dispatch(loadSuccess(res.data as Paginated<Book>));
  } catch (e) {
    dispatch(loadFailed("Couldn't load books"));
    throw e;
  }
};

export const loadNextBooks = (): AppThunk => async (dispatch, getState) => {
  dispatch(startLoading());
  const next = getState().books.pagination.next;
  try {
    const res = await api({ url: next, baseURL: '' });

    dispatch(loadSuccess(res.data as Paginated<Book>));
  } catch (e) {
    dispatch(loadFailed("Couldn't load rest of the books"));
    throw e;
  }
};

export const loadBook = (id: string): AppThunk => async dispatch => {
  dispatch(startLoading());

  try {
    const res = await api.get('/books/' + id);

    dispatch(bookLoaded(res.data as Book));
  } catch (e) {
    dispatch(loadFailed("Couldn't load books"));
    throw e;
  }
};

export const createBook = (data: Book): AppThunk => async dispatch => {
  try {
    const res = await api.post('/books', {
      ...data,
      authors: data.authors?.map(x => x.id),
      narrators: data.narrators?.map(x => x.id),
      publishers: data.publishers?.map(x => x.id),
      categories: data.categories?.map(
        x => x.book_category?.categoryId || x.id
      ),
    });
    dispatch(bookCreated(res.data as Book));
  } catch (e) {
    dispatch(saveFailed("Couldn't save book"));
    throw e;
  }
};

export const updateBook = (data: Book): AppThunk => async dispatch => {
  console.log(data);
  try {
    await api.patch(`/books/${data.id}`, {
      ...data,
      categories: data.categories?.map(x => x.id),
      authors: data.authors && data.authors.map(x => x.id),
      narrators: data.narrators && data.narrators.map(x => x.id),
      publishers: data.publishers && data.publishers.map(x => x.id),
    });

    // if (res.status !== 200) throw Error();

    dispatch(bookUpdated(data));
  } catch (e) {
    dispatch(loadFailed("Couldn't save book"));
    throw e;
  }
};

export const deleteBook = (data: Book): AppThunk => async dispatch => {
  try {
    const { id } = data;
    await api.delete(`/books/${id}`);

    // if (res.status !== 200) throw Error();

    dispatch(bookDeleted(data));
  } catch (e) {
    dispatch(loadFailed("Couldn't delete book"));
    // throw e;
  }
};

export const addChapter = (
  bookId: string,
  data: Chapter
): AppThunk => async dispatch => {
  try {
    const res = await api.post(`/books/${bookId}/chapters/`, data);

    // if (res.status !== 201) throw Error();

    dispatch(chapterAdded({ bookId, chapter: res.data as Chapter }));
  } catch (e) {
    console.log(e.response);
    if (e.response) {
      dispatch(saveFailed(e.response.data.message));
    } else {
      dispatch(saveFailed('Network error'));
    }

    throw e;
  }
};

export const updateChapter = (
  bookId: string,
  data: Chapter
): AppThunk => async dispatch => {
  try {
    const { id, name, order, no, audio, duration } = data;
    const res = await api.patch(`/books/${bookId}/chapters/${id}`, {
      name,
      order,
      no,
      audio,
      duration,
    });

    dispatch(
      chapterUpdated({
        bookId,
        chapter: res.data,
      })
    );
  } catch (e) {
    dispatch(loadFailed("Couldn't update chapter"));
    throw e;
  }
};

export const deleteChapter = (
  bookId: string,
  data: Chapter
): AppThunk => async dispatch => {
  try {
    const { id } = data;
    await api.delete(`/books/${bookId}/chapters/${id}`);

    dispatch(
      chapterDeleted({
        bookId,
        chapter: data,
      })
    );
  } catch (e) {
    dispatch(loadFailed("Couldn't delete chapter"));
    throw e;
  }
};

export const {
  startLoading,
  loadSuccess,
  loadFailed,
  bookCreated,
  saveFailed,
  bookUpdated,
  promptDelete,
  cancelDelete,
  bookDeleted,
  resetSubmission,
  bookLoaded,
  chapterAdded,
  openModal,
  closeModal,
  chapterUpdated,
  editChapter,
  promptChapterDelete,
  cancelChapterDelete,
  chapterDeleted,
  gotoPage,
} = authSlice.actions;

export default authSlice.reducer;
