import { SliceCaseReducers } from '@reduxjs/toolkit';

import { GenericObject, createGenericModule, GenericState } from './common';
import { RootState } from './store';

export interface Category extends GenericObject {
  name: string;
  id: string;
  book_category: {
    categoryId: string;
  };
}

interface CategoriesState extends GenericState<Category> {}

const { slice: categorySlice, thunks, adapter } = createGenericModule<
  Category,
  SliceCaseReducers<CategoriesState>
>({
  name: 'categories',
  initialState: {},
  reducers: {},
});

export const {
  openModal,
  closeModal,
  editObject: editCategory,
  promptDelete,
  cancelDelete,
} = categorySlice.actions;

export const { fetchAll, create, update, del: deleteCategory } = thunks;
export const categorySelectors = adapter.getSelectors(
  (state: RootState) => state.categories
);

export default categorySlice.reducer;
