import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { RootState } from 'modules/store';
import Loading from 'screens/Loading';
import Login from 'screens/Login';
import Authors from 'screens/Authors';
import Narrators from 'screens/Narrators';
import Books from 'screens/Books';
import Categories from 'screens/Categories';
import Nav from 'components/Nav';
import Home from 'screens/Home';
import Balance from 'screens/Balance';
import Publishers from 'screens/Publishers';
import Notification from 'screens/Notification';

export default () => {
  const { token, loading } = useSelector((state: RootState) => state.auth);

  const cls = useStyles();
  if (loading) {
    return <Loading message="Signing in!" />;
  }
  if (!token) {
    return <Login />;
  }
  return (
    <Router>
      <div style={{ display: 'flex' }}>
        <Nav />
        <main className={cls.main}>
          <div className={cls.toolbar} />
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/notification">
              <Notification />
            </Route>
            <Route path="/publishers">
              <Publishers />
            </Route>
            <Route path="/authors">
              <Authors />
            </Route>
            <Route path="/narrators">
              <Narrators />
            </Route>
            <Route path="/books">
              <Books />
            </Route>
            <Route path="/categories">
              <Categories />
            </Route>
            <Route path="/balance">
              <Balance />
            </Route>
          </Switch>
        </main>
      </div>
    </Router>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    padding: theme.spacing(3),
    width: '100%',
  },
  toolbar: theme.mixins.toolbar,
}));
