import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import useForm from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  Category,
  create,
  closeModal,
  update,
  openModal,
} from 'modules/categories';
import { RootState } from 'modules/store';
import { ResponseStatus } from 'lib/response-status';

export default () => {
  const { currentlyEditing, saveFailed, message, status } = useSelector(
    (state: RootState) => state.categories
  );
  const { register, errors, handleSubmit, reset } = useForm<Category>({
    defaultValues: currentlyEditing,
  });

  const dispatch = useDispatch();

  const [close, setClose] = useState(false);

  useEffect(() => {
    if (status === ResponseStatus.FULFILLED) {
      reset();
      dispatch(openModal());
      if (close) dispatch(closeModal());
    }
  }, [close, status, dispatch, reset]);

  async function onSubmit(data: Category) {
    if (currentlyEditing) {
      dispatch(update({ ...currentlyEditing, ...data }));
    } else {
      dispatch(create(data));
    }
  }

  async function saveAndClose(data: Category) {
    setClose(true);
    onSubmit(data);
  }

  const cls = useStyles();
  return (
    <form onSubmit={handleSubmit(saveAndClose)}>
      <TextField
        name="name"
        inputRef={register({ required: 'Name is required' })}
        error={Boolean(errors.name)}
        helperText={errors.name && errors.name.message}
        label="Name"
        variant="outlined"
        fullWidth
        margin="normal"
      />
      {saveFailed && (
        <FormControl error>
          <FormHelperText>{message}</FormHelperText>
        </FormControl>
      )}
      <div className={cls.buttonRow}>
        <Button type="submit" color="primary" variant="contained">
          Save
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          type="button"
          color="primary"
          variant="contained"
          className={cls.saveAndNew}
        >
          Save and add new
        </Button>
        <div className={cls.gap}></div>
        <Button
          onClick={() => dispatch(closeModal())}
          color="secondary"
          variant="contained"
          type="button"
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

const useStyles = makeStyles({
  buttonRow: {
    display: 'flex',
    marginTop: '1rem',
  },
  gap: {
    flexGrow: 1,
  },
  saveAndNew: {
    marginLeft: '1rem',
  },
});
