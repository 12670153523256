import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import useForm from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { login } from 'modules/auth';
import { RootState } from 'modules/store';

export interface LoginData {
  username: string;
  password: string;
}

export default () => {
  const cls = useStyles();

  const { register, errors, handleSubmit } = useForm<LoginData>();

  const dispatch = useDispatch();
  const message = useSelector((state: RootState) => state.auth.message);

  function onSubmit(data: LoginData) {
    dispatch(login(data));
  }

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Container className={cls.container}>
      <Paper className={cls.paper}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            className={cls.input}
            name="username"
            inputRef={register({ required: 'Username is required' })}
            label="Username"
            variant="outlined"
            margin="normal"
            error={Boolean(errors.username)}
            helperText={errors.username && errors.username.message}
          />
          <br />
          <TextField
            className={cls.input}
            name="password"
            inputRef={register({ required: 'Password is required' })}
            label="Password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            margin="normal"
            error={Boolean(errors.password)}
            helperText={errors.password && errors.password.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <br />
          {message && (
            <>
              <FormControl error>
                <FormHelperText>{message}</FormHelperText>
              </FormControl>
              <br />
            </>
          )}
          <Button
            type="submit"
            className={cls.button}
            variant="contained"
            color="primary"
          >
            Log in
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: '2rem 3rem',
    textAlign: 'center',
  },
  button: {
    marginTop: '1rem',
  },
  input: {
    width: '100%',
  },
});
