import { configureStore, combineReducers, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import authReducer from './auth';
import authorsReducer from './authors';
import publishersReducer from './publishers';
import narratorsReducer from './narrators';
import booksReducer from './books';
import categories from './categories';
import sales from './sales';

const rootReducer = combineReducers({
  auth: authReducer,
  authors: authorsReducer,
  narrators: narratorsReducer,
  publishers: publishersReducer,
  books: booksReducer,
  categories,
  sales,
});

const store = configureStore({ reducer: rootReducer });

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
