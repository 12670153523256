import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import useForm from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'modules/store';
import { sendNotification } from 'modules/auth';
import { Snackbar } from '@material-ui/core';

export default () => {
  const { notificationStatus } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const { register, errors, handleSubmit } = useForm();

  async function onSubmit(data: any) {
    dispatch(sendNotification({ ...data, bookId: data.bookId || undefined }));
  }

  const cls = useStyles();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {notificationStatus === 'SUCCESS' && (
        <Snackbar open message="Notification sent" />
      )}
      {notificationStatus === 'FAILED' && (
        <Snackbar open message="Failed to send notification" />
      )}
      <TextField
        name="title"
        inputRef={register({ required: 'Title is required' })}
        error={Boolean(errors.title)}
        helperText={errors.title && errors.title.message}
        label="Title"
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <TextField
        name="body"
        inputRef={register({ required: 'Body is required' })}
        error={Boolean(errors.body)}
        helperText={errors.body && errors.body.message}
        label="Body"
        variant="outlined"
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <TextField
        name="bookId"
        inputRef={register}
        error={Boolean(errors.bookId)}
        helperText={errors.bookId && errors.bookId.message}
        label="Book ID"
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <div className={cls.buttonRow}>
        <Button type="submit" color="primary" variant="contained">
          Send
        </Button>
      </div>
    </form>
  );
};

const useStyles = makeStyles({
  buttonRow: {
    display: 'flex',
    marginTop: '1rem',
  },
  gap: {
    flexGrow: 1,
  },
  saveAndNew: {
    marginLeft: '1rem',
  },
});
