import React, { useEffect, useMemo, useState } from 'react';
import { RootState } from 'modules/store';
import { fetch } from 'modules/sales';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { ResponseStatus } from 'lib/response-status';
import { useEntity } from 'components/BookForm';
import Loading from './Loading';
import Autocomplete from '@material-ui/lab/Autocomplete';

function NumberCard({
  x,
  text,
}: {
  x: number | React.ReactNode;
  text: string;
}) {
  return (
    <Grid item>
      <Card>
        <CardContent>
          <Typography variant="h3">{x}</Typography>
        </CardContent>
        <CardContent>
          <Typography>{text}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default function Home() {
  const dispatch = useDispatch();
  const { ids, status, entities: records } = useSelector(
    (state: RootState) => state.sales
  );
  const { user } = useSelector((state: RootState) => state.auth);

  const [from, setFrom] = useState(new Date('01/01/2021'));
  const [to, setTo] = useState(new Date());

  const { options: authorOptions, setKeyword: setAuthorKeyword } = useEntity(
    'authors'
  );
  const [author, setAuthor] = useState('');

  const {
    options: narratorOptions,
    setKeyword: setNarratorKeyword,
  } = useEntity('narrators');
  const [narrator, setNarrator] = useState('');

  const {
    options: publisherOptions,
    setKeyword: setPublisherKeyword,
  } = useEntity('publishers');
  const [publisher, setPublisher] = useState('');

  const { options: bookOptions, setKeyword: setBookKeyword } = useEntity(
    'books'
  );
  const [book, setBook] = useState('');

  const isCreator = user?.role === 'USER';

  useEffect(() => {
    dispatch(
      fetch({
        from,
        to,
        author,
        narrator,
        publisher,
        book,
      })
    );
  }, [dispatch, from, to, author, narrator, publisher, book]);

  const {
    authorsShare,
    narratorsShare,
    publishersShare,
    total,
  } = useMemo(() => {
    let total = 0,
      authorsShare = 0,
      narratorsShare = 0,
      publishersShare = 0;
    for (const id of ids) {
      const { price, authorShare, narratorShare, publisherShare } = records[
        id
      ]!;
      authorsShare += authorShare;
      narratorsShare += narratorShare;
      publishersShare += publisherShare;
      total += price;
    }
    return { authorsShare, narratorsShare, publishersShare, total };
  }, [ids, records]);

  const sunBoiShare = total - (authorsShare + narratorsShare + publishersShare);

  return (
    <Grid container spacing={3}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={5}>
          <Grid item>
            <KeyboardDatePicker
              variant="inline"
              format="dd MMM yyyy"
              value={from}
              maxDate={to}
              onChange={v => v && setFrom(v)}
            />
          </Grid>
          <Grid item>
            <KeyboardDatePicker
              variant="inline"
              format="dd MMM yyyy"
              value={to}
              maxDate={new Date()}
              onChange={v => v && setTo(v)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          {!isCreator && (
            <Grid item>
              <Autocomplete
                options={authorOptions}
                getOptionLabel={option => option.name}
                onChange={(_e, v) => setAuthor(v?.id)}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={e => setAuthorKeyword(e.target.value)}
                    label="Author"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                )}
              />
            </Grid>
          )}
          {!isCreator && (
            <Grid item>
              <Autocomplete
                options={narratorOptions}
                getOptionLabel={option => option.name}
                onChange={(_e, v) => setNarrator(v?.id)}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={e => setNarratorKeyword(e.target.value)}
                    label="Narrator"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                )}
              />
            </Grid>
          )}

          {!isCreator && (
            <Grid item>
              <Autocomplete
                options={publisherOptions}
                getOptionLabel={option => option.name}
                onChange={(_e, v) => setPublisher(v?.id)}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={e => setPublisherKeyword(e.target.value)}
                    label="Publisher"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                )}
              />
            </Grid>
          )}

          <Grid item>
            <Autocomplete
              options={bookOptions}
              getOptionLabel={option => option.name}
              onChange={(_e, v) => setBook(v?.id)}
              renderInput={params => (
                <TextField
                  {...params}
                  onChange={e => setBookKeyword(e.target.value)}
                  label="Book"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              )}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>

      {status === ResponseStatus.PENDING ? (
        <Grid item xs={12}>
          <Loading message="Loading..." />
        </Grid>
      ) : (
        <>
          <NumberCard x={ids.length} text="Books sold" />
          <NumberCard x={`৳${total}`} text="Total collected" />
          <NumberCard x={`৳${authorsShare.toFixed(1)}`} text="To authors" />
          <NumberCard x={`৳${narratorsShare.toFixed(1)}`} text="To narrators" />
          <NumberCard
            x={`৳${publishersShare.toFixed(1)}`}
            text="To publishers"
          />
          <NumberCard x={`৳${sunBoiShare.toFixed(1)}`} text="To ShunBoi" />
        </>
      )}
    </Grid>
  );
}
