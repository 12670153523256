import React from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import BookForm from 'components/BookForm';

export default () => {
  const cls = useStyles();
  return (
    <Container>
      <Paper className={cls.formPaper}>
        <BookForm />
      </Paper>
    </Container>
  );
};

const useStyles = makeStyles({
  formPaper: {
    padding: '2rem',
    marginBottom: '2rem',
  },
});
