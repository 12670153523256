import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'modules/store';
import {
  loadBooks,
  promptDelete,
  cancelDelete,
  deleteBook,
  gotoPage,
  loadNextBooks,
} from 'modules/books';
import RouterLink from 'components/RouterLink';

const PER_PAGE = 10;

export default () => {
  const { books, toDelete, pagination } = useSelector(
    (state: RootState) => state.books
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadBooks());
  }, [dispatch]);

  const cls = useStyles();
  return (
    <Container>
      <Button
        component={RouterLink}
        to="/books/create"
        variant="contained"
        color="primary"
        className={cls.addButton}
        startIcon={<AddIcon />}
      >
        Add book
      </Button>
      {toDelete && (
        <Modal
          open={Boolean(toDelete)}
          className={cls.modal}
          keepMounted={false}
        >
          <Paper className={cls.formPaper}>
            <Typography variant="h5">
              Sure to delete {toDelete!.name}?
            </Typography>
            <Button
              onClick={() => dispatch(deleteBook(toDelete!))}
              variant="contained"
              color="secondary"
              className={cls.promptButton}
            >
              Delete
            </Button>
            <Button
              onClick={() => dispatch(cancelDelete())}
              variant="contained"
              color="primary"
              className={cls.promptButton}
            >
              Cancel
            </Button>
          </Paper>
        </Modal>
      )}
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Authors</TableCell>
              <TableCell>Narrators</TableCell>
              <TableCell>Categories</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {books
              .slice(
                pagination.currentPage * PER_PAGE,
                (pagination.currentPage + 1) * PER_PAGE
              )
              .map(book => (
                <TableRow key={book.id}>
                  <TableCell>
                    <Link component={RouterLink} to={`/books/${book.id}`}>
                      {book.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {book.authors && book.authors.map(x => x.name).join(', ')}
                  </TableCell>
                  <TableCell>
                    {book.narrators &&
                      book.narrators.map(x => x.name).join(', ')}
                  </TableCell>
                  <TableCell>
                    {book.categories?.map(x => x.name).join(', ')}
                  </TableCell>
                  <TableCell>{book.price}</TableCell>
                  <TableCell>
                    <IconButton
                      component={RouterLink}
                      to={`/books/${book.id}/edit`}
                      size="small"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => dispatch(promptDelete(book))}
                      size="small"
                    >
                      <DeleteIcon color="error" fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[PER_PAGE]}
                colSpan={7}
                count={pagination.resourceCount}
                rowsPerPage={PER_PAGE}
                page={pagination.currentPage}
                onChangePage={(_e, page) => {
                  if (page === pagination.pagesLoaded) {
                    dispatch(loadNextBooks());
                  }
                  dispatch(gotoPage(page));
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </Container>
  );
};

const useStyles = makeStyles({
  formPaper: {
    padding: '2rem',
    marginBottom: '2rem',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  promptButton: {
    margin: '1rem 1rem 0 0',
  },
  addButton: {
    marginBottom: '1rem',
  },
});
