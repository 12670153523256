import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import AudioIcon from '@material-ui/icons/Audiotrack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useForm from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Chapter, addChapter, closeModal, updateChapter } from 'modules/books';
import { RootState } from 'modules/store';
import useFileInput from 'lib/fileInput';

export default () => {
  const { id: bookId } = useParams();

  const {
    chapters: { currentlyEditing },
    saveFailed,
    message,
  } = useSelector((state: RootState) => state.books);

  const {
    register,
    errors,
    handleSubmit,
    reset,
    setValue,
    setError,
    clearError,
  } = useForm<Chapter>({
    defaultValues: currentlyEditing,
  });

  const dispatch = useDispatch();

  async function onSubmit(data: Chapter) {
    if (currentlyEditing) {
      await dispatch(updateChapter(bookId!, { ...currentlyEditing, ...data }));
    } else {
      await dispatch(addChapter(bookId!, data));
    }
    reset();
    resetAudio();
  }

  async function saveAndClose(data: Chapter) {
    await onSubmit(data);
    dispatch(closeModal());
  }

  const {
    file: audio,
    status: audioStatus,
    duration,
    size,
    handleSelect: handleAudioSelect,
    reset: resetAudio,
  } = useFileInput({
    name: 'audio',
    type: 'private',
    setValue,
    setError,
    clearError,
    dir: 'chapter-audios',
    defaultUrl: currentlyEditing && currentlyEditing.audio,
  });

  useEffect(() => {
    register(
      { name: 'audio' },
      {
        validate: value => {
          if (!value && !audioStatus.inProgress) return 'Audio is required';
          return !audioStatus.inProgress || 'Wait for the upload to complete';
        },
      }
    );
  }, [register, audioStatus.inProgress]);

  useEffect(() => {
    setValue('duration', duration);
  }, [duration, setValue]);
  useEffect(() => {
    setValue('fileSize', size);
  }, [size, setValue]);

  const cls = useStyles();
  return (
    <form onSubmit={handleSubmit(saveAndClose)}>
      <TextField
        name="name"
        inputRef={register({ required: 'Name is required' })}
        error={Boolean(errors.name)}
        helperText={errors.name && errors.name.message}
        label="Name"
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <TextField
        name="no"
        inputRef={register}
        error={Boolean(errors.no)}
        helperText={errors.no && errors.no.message}
        label="Chapter No"
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <TextField
        name="order"
        type="number"
        inputRef={register({ required: 'Chapter order is required' })}
        error={Boolean(errors.order)}
        helperText={errors.order && errors.order.message}
        label="Chapter order"
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <TextField
        name="duration"
        type="number"
        inputProps={{ step: 'any' }}
        inputRef={register({ required: 'Duration order is required' })}
        error={Boolean(errors.duration)}
        helperText={errors.duration && errors.duration.message}
        label="Duration"
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <TextField
        name="fileSize"
        type="number"
        inputRef={register({ required: 'File size order is required' })}
        error={Boolean(errors.fileSize)}
        helperText={errors.fileSize && errors.fileSize.message}
        label="File size"
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <FormControl fullWidth margin="normal" error={Boolean(errors.audio)}>
        <FormLabel>Chapter Audio</FormLabel>
        <input
          name="audio"
          id="audio"
          onChange={handleAudioSelect}
          className={cls.fileInput}
          type="file"
          accept="audio/*"
        />
        <label htmlFor="audio">
          <div className={cls.fileContainer}>
            {audio ? <span className={cls.file}>{audio}</span> : <AudioIcon />}
            {audioStatus.inProgress && (
              <div className={cls.progressContainer}>
                <CircularProgress
                  variant={audioStatus.indefinite ? 'indeterminate' : 'static'}
                  value={audioStatus.progress * 100}
                />
              </div>
            )}
          </div>
        </label>
        {errors.audio && (
          <FormHelperText>{errors.audio.message}</FormHelperText>
        )}
      </FormControl>
      {saveFailed && (
        <FormControl error>
          <FormHelperText>{message}</FormHelperText>
        </FormControl>
      )}
      <div className={cls.buttonRow}>
        <Button type="submit" color="primary" variant="contained">
          Save
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          type="button"
          color="primary"
          variant="contained"
          className={cls.saveAndNew}
        >
          Save and add new
        </Button>
        <div className={cls.gap}></div>
        <Button
          onClick={() => dispatch(closeModal())}
          color="secondary"
          variant="contained"
          type="button"
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonRow: {
    display: 'flex',
    marginTop: '1rem',
  },
  gap: {
    flexGrow: 1,
  },
  saveAndNew: {
    marginLeft: '1rem',
  },
  fileContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: '100px',
    minWidth: '100px',
    border: `1px solid ${theme.palette.text.primary}`,
    marginTop: '1rem',
    borderRadius: '4px',
    position: 'relative',
    padding: '2rem',
  },
  fileInput: {
    display: 'none',
  },
  file: {
    textAlign: 'center',
  },
  progressContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, .5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
