import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'modules/store';
import {
  fetchAll,
  openModal,
  editObject,
  publisherSelectors,
  promptDelete,
  cancelDelete,
  deletePublisher,
} from 'modules/publishers';
import PublisherForm from 'components/PublisherForm';

export default () => {
  const { modalOpen, toDelete } = useSelector(
    (state: RootState) => state.publishers
  );
  const publishers = useSelector(publisherSelectors.selectAll);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAll());
  }, [dispatch]);

  const cls = useStyles();
  return (
    <Container>
      <Button
        onClick={() => dispatch(openModal())}
        variant="contained"
        color="primary"
        className={cls.addButton}
        startIcon={<AddIcon />}
      >
        Add publisher
      </Button>
      <Modal open={modalOpen} className={cls.modal}>
        <Paper className={cls.formPaper}>
          <PublisherForm />
        </Paper>
      </Modal>
      {toDelete && (
        <Modal
          open={Boolean(toDelete)}
          className={cls.modal}
          keepMounted={false}
        >
          <Paper className={cls.formPaper}>
            <Typography variant="h5">
              Sure to delete {toDelete!.name}?
            </Typography>
            <Button
              onClick={() => dispatch(deletePublisher(toDelete!))}
              variant="contained"
              color="secondary"
              className={cls.promptButton}
            >
              Delete
            </Button>
            <Button
              onClick={() => dispatch(cancelDelete())}
              variant="contained"
              color="primary"
              className={cls.promptButton}
            >
              Cancel
            </Button>
          </Paper>
        </Modal>
      )}
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Edit</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {publishers.map(publisher => (
              <TableRow key={publisher.id}>
                <TableCell>{publisher.name}</TableCell>
                <TableCell>{publisher.username}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      dispatch(editObject(publisher));
                      dispatch(openModal());
                    }}
                    size="small"
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => dispatch(promptDelete(publisher))}
                    size="small"
                  >
                    <DeleteIcon color="error" fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};

const useStyles = makeStyles({
  formPaper: {
    padding: '2rem',
    marginBottom: '2rem',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  promptButton: {
    margin: '1rem 1rem 0 0',
  },
  addButton: {
    marginBottom: '1rem',
  },
});
