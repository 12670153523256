import {
  PayloadAction,
  createSlice,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
  createAsyncThunk,
  createEntityAdapter,
  EntityState,
} from '@reduxjs/toolkit';
import api from 'api';
import { AxiosError } from 'axios';
import { ResponseStatus } from 'lib/response-status';

export interface GenericObject {
  id: string;
}

export interface GenericState<T extends GenericObject> extends EntityState<T> {
  modalOpen: boolean;
  loading?: boolean;
  message?: string;
  saveFailed: boolean;
  currentlyEditing?: T;
  status: ResponseStatus;
  toDelete?: T;
}

export function handleError(err: AxiosError, rejectWithValue: any) {
  if (!err.isAxiosError || !err.response)
    return rejectWithValue('Network error');

  if (err.response.status >= 500) return rejectWithValue('Server failed');

  return rejectWithValue(err.response.data.message);
}

export const createGenericModule = <
  T extends GenericObject,
  Reducers extends SliceCaseReducers<GenericState<T>>
>({
  name = '',
  initialState = {},
  reducers,
}: {
  name: string;
  initialState: Partial<GenericState<T>>;
  reducers: ValidateSliceCaseReducers<GenericState<T>, Reducers>;
}) => {
  const genericInitialState = {
    list: [],
    modalOpen: false,
    saveFailed: false,
    status: ResponseStatus.INACTIVE,
  };

  const adapter = createEntityAdapter<T>({
    selectId: entity => entity.id,
    sortComparer: (a, b) => a.id.localeCompare(b.id),
  });

  const fetchAll = createAsyncThunk<T[], void, { rejectValue: string }>(
    `${name}/fetchAll`,
    async (_arg, { rejectWithValue }) => {
      try {
        const res = await api.get(`/${name}`, { params: { limit: 500 } });

        return res.data.data;
      } catch (e) {
        return handleError(e, rejectWithValue);
      }
    }
  );

  const create = createAsyncThunk<T, T, { rejectValue: string }>(
    `${name}/create`,
    async (data, { rejectWithValue }) => {
      try {
        const res = await api.post(`/${name}`, data);
        return res.data;
      } catch (e) {
        return handleError(e, rejectWithValue);
      }
    }
  );

  const update = createAsyncThunk<T, T, { rejectValue: string }>(
    `${name}/update`,
    async (data, { rejectWithValue }) => {
      try {
        const { id } = data;
        await api.patch(`/${name}/${id}`, {
          ...data,
          id: undefined,
        });

        return data;
      } catch (e) {
        return handleError(e, rejectWithValue);
      }
    }
  );

  const del = createAsyncThunk<T, T, { rejectValue: string }>(
    `${name}/delete`,
    async (data, { rejectWithValue }) => {
      try {
        const { id } = data;
        await api.delete(`/${name}/${id}`);
        return data;
        // if (res.status === 200) return data;
        // else return rectWithValue(res.data.message);
      } catch (e) {
        return handleError(e, rejectWithValue);
      }
    }
  );

  return {
    thunks: {
      fetchAll,
      create,
      update,
      del,
    },
    adapter,
    slice: createSlice({
      name,
      initialState: {
        ...genericInitialState,
        ...adapter.getInitialState(),
        ...initialState,
      } as GenericState<T>,
      reducers: {
        openModal(state: GenericState<T>) {
          state.modalOpen = true;
          state.saveFailed = false;
          state.message = '';
          state.status = ResponseStatus.INACTIVE;
        },
        closeModal(state: GenericState<T>) {
          state.modalOpen = false;
          state.currentlyEditing = undefined;
        },
        editObject(state: GenericState<T>, { payload }: PayloadAction<T>) {
          state.currentlyEditing = payload;
        },
        promptDelete(state: GenericState<T>, { payload }: PayloadAction<T>) {
          state.toDelete = payload;
        },
        cancelDelete(state: GenericState<T>) {
          state.toDelete = undefined;
        },
        ...reducers,
      },
      extraReducers: builder => {
        // Fetch
        builder.addCase(fetchAll.pending, state => {
          state.status = ResponseStatus.PENDING;
          state.loading = true;
        });
        builder.addCase(fetchAll.fulfilled, (state, action) => {
          state.status = ResponseStatus.FULFILLED;
          state.loading = false;
          state.message = '';
          adapter.setAll(state as GenericState<T>, action.payload);
          // state.list = action.payload as Draft<T>[];
        });
        builder.addCase(fetchAll.rejected, (state, action) => {
          state.status = ResponseStatus.REJECTED;
          state.loading = false;
          state.message = action.payload;
        });

        // Create
        builder.addCase(create.pending, state => {
          state.status = ResponseStatus.PENDING;
          state.loading = true;
        });
        builder.addCase(create.fulfilled, (state, { payload }) => {
          state.status = ResponseStatus.FULFILLED;
          adapter.addOne(state as GenericState<T>, payload);
          state.saveFailed = false;
          state.message = '';
        });
        builder.addCase(create.rejected, (state, { payload }) => {
          state.status = ResponseStatus.REJECTED;
          state.saveFailed = true;
          state.message = payload;
        });

        // Update
        builder.addCase(update.pending, state => {
          state.status = ResponseStatus.PENDING;
          state.loading = true;
        });
        builder.addCase(update.fulfilled, (state, { payload }) => {
          state.status = ResponseStatus.FULFILLED;
          adapter.upsertOne(state as GenericState<T>, payload);
          state.currentlyEditing = undefined;
          state.saveFailed = false;
          state.message = '';
        });
        builder.addCase(update.rejected, (state, { payload }) => {
          state.status = ResponseStatus.REJECTED;
          state.saveFailed = true;
          state.message = payload;
        });

        // Delete
        builder.addCase(del.pending, state => {
          state.status = ResponseStatus.PENDING;
          state.loading = true;
        });
        builder.addCase(del.fulfilled, (state, { payload }) => {
          state.status = ResponseStatus.FULFILLED;
          adapter.removeOne(state as GenericState<T>, payload.id);
          state.toDelete = undefined;
        });
        builder.addCase(del.rejected, (state, { payload }) => {
          state.status = ResponseStatus.REJECTED;
          state.saveFailed = true;
          state.message = payload;
        });
      },
    }),
  };
};
