import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import BookList from './BookList';
import CreateBook from './CreateBook';
import Book from './Book';

export default () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}`} exact>
        <BookList />
      </Route>
      <Route path={`${match.url}/create`}>
        <CreateBook />
      </Route>
      <Route path={`${match.url}/:id`} exact>
        <Book />
      </Route>
      <Route path={`${match.url}/:id/edit`}>
        <CreateBook />
      </Route>
    </Switch>
  );
};
