import { SliceCaseReducers } from '@reduxjs/toolkit';

import { GenericObject, createGenericModule, GenericState } from './common';
import { RootState } from './store';

export interface Publisher extends GenericObject {
  name: string;
  email: string;
  username: string;
  password: string;
}

interface PublishersState extends GenericState<Publisher> {}

const { slice: publisherSlice, thunks, adapter } = createGenericModule<
  Publisher,
  SliceCaseReducers<PublishersState>
>({
  name: 'publishers',
  initialState: {},
  reducers: {},
});

export const {
  openModal,
  closeModal,
  editObject,
  promptDelete,
  cancelDelete,
} = publisherSlice.actions;

export const { fetchAll, create, update, del: deletePublisher } = thunks;
export const publisherSelectors = adapter.getSelectors(
  (state: RootState) => state.publishers
);

export default publisherSlice.reducer;
