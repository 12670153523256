import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  message?: string;
}

export default ({ message }: Props) => {
  const cls = useStyles();
  return (
    <div className={cls.root}>
      <Typography className={cls.message} variant="body1">
        {message}
      </Typography>
      <LinearProgress className={cls.progress} />
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  message: {
    marginBottom: '2rem',
  },
  progress: {
    width: '25vw',
  },
});
