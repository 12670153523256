import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import AuthorIcon from '@material-ui/icons/Person';
import NarratorIcon from '@material-ui/icons/RecordVoiceOver';
import BookIcon from '@material-ui/icons/MenuBook';
import MoneyIcon from '@material-ui/icons/Money';
import BusinessIcon from '@material-ui/icons/Business';
import CategoryIcon from '@material-ui/icons/Category';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from 'modules/store';
import { logout } from 'modules/auth';
import { ListItemLink } from 'components/RouterLink';

export default () => {
  const cls = useStyles();
  const user = useSelector((state: RootState) => state.auth.user);

  const dispatch = useDispatch();

  function handleLogout() {
    dispatch(logout());
  }

  const location = useLocation();

  const isCreator = user?.role === 'USER';

  const drawerItems = [
    { name: 'Home', path: '/', icon: <HomeIcon /> },
    {
      name: 'Balance',
      path: '/balance',
      icon: <MoneyIcon />,
      hidden: !isCreator,
    },
    {
      name: 'Authors',
      path: '/authors',
      icon: <AuthorIcon />,
      hidden: isCreator,
    },
    {
      name: 'Narrators',
      path: '/narrators',
      icon: <NarratorIcon />,
      hidden: isCreator,
    },
    {
      name: 'Publishers',
      path: '/publishers',
      icon: <BusinessIcon />,
      hidden: isCreator,
    },
    { name: 'Books', path: '/books', icon: <BookIcon />, hidden: isCreator },
    {
      name: 'Categories',
      path: '/categories',
      icon: <CategoryIcon />,
      hidden: isCreator,
    },
    {
      name: 'Notification',
      path: '/Notification',
      icon: <NotificationsIcon />,
      hidden: isCreator,
    },
  ];

  const drawer = (
    <div>
      <List>
        {drawerItems
          .filter(x => !x.hidden)
          .map(x => (
            <ListItemLink
              selected={x.path === location.pathname}
              key={x.path}
              primary={x.name}
              to={x.path}
              icon={x.icon}
            />
          ))}
      </List>
    </div>
  );
  const [drawerMobileOpen, setDrawerMobileOpen] = useState(false);
  const handleDrawerToggle = () => setDrawerMobileOpen(!drawerMobileOpen);
  return (
    <>
      <AppBar position="fixed" className={cls.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={cls.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="h1" className={cls.title}>
            ShunBoi Dashboard
          </Typography>
          <Hidden xsDown implementation="css">
            <Typography>{user && user.name}</Typography>
          </Hidden>
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <nav className={cls.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            open={drawerMobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: cls.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: cls.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <div className={cls.toolbar}></div>
    </>
  );
};

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
